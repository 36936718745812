<template>
    <div class="new_delivery">
        <div class="label">
            {{ $t('message.form_label_create_delivery') }}
        </div>

        <div class="field">
            <input type="text" name="" value="" class="input">
        </div>

        <div class="row">
            <div class="date">
                <div class="label">
                    {{ $t('message.form_label_choose_date') }}
                </div>

                <div class="field">
                    <input type="text" v-model="period" class="input" id="calendar">
                </div>
            </div>

            <div class="days">
                <div class="label">
                    {{ $t('message.form_label_how_long') }}
                </div>

                <div class="field">
                    <input type="number" v-model="amount" class="input">

                    <button type="button" class="up_btn btn" @click.prevent="amount++"></button>
                    <button type="button" class="down_btn btn" @click.prevent="amount++"></button>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
    import { onMounted, ref } from 'vue'
    import AirDatepicker from 'air-datepicker'

    import 'air-datepicker/air-datepicker.css'


    const amount = ref(30),
        period = ref(new Date().toLocaleDateString('ru-RU'))


    onMounted(() => {
        // Init calendar
        new AirDatepicker('#calendar', {
            autoClose: true,
            minDate: new Date()
        })
    })
</script>


<style scoped>
    .new_delivery
    {
        margin-top: 20px;
    }


    .new_delivery .label
    {
        color: #808080;

        margin-bottom: 5px;
    }


    .new_delivery .field
    {
        position: relative;
    }


    .new_delivery .field .input
    {
        color: var(--text_color);
        font-family: var(--font_family);
        font-size: 18px;

        display: block;

        width: 100%;
        height: 43px;

        border: none;
        border-bottom: 1px solid #dbe0e4;
        background: none;
    }


    .new_delivery .row
    {
        display: flex;

        margin-top: 25px;
        margin-bottom: -10px;

        justify-content: space-between;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
    }

    .new_delivery .row > *
    {
        display: flex;

        margin-bottom: 10px;

        justify-content: flex-start;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
    }


    .new_delivery .row .label
    {
        margin-right: 10px;
        margin-bottom: 0;
    }


    .new_delivery .row .field
    {
        width: 85px;
    }


    .new_delivery .row .input
    {
        color: var(--text_color);
        font-family: var(--font_family);
        font-size: 12px;

        display: block;

        width: 100%;
        height: 30px;

        transition: border-color .2s linear;
        text-align: center;

        border: 1px solid #dbe0e4;
        border-radius: 4px;
        background: none;
    }

    .new_delivery .row .input:focus
    {
        border-color: #49aa4f;
    }


    .new_delivery .row .days .field
    {
        width: 56px;
    }

    .new_delivery .row .days .input
    {
        padding-right: 18px;
    }



    .new_delivery .row .btn
    {
        position: absolute;
        z-index: 3;
        top: 0;
        right: 0;

        width: 19px;
        height: 15px;

        border-left: 1px solid #dbe0e4;
    }

    .new_delivery .row .btn:before
    {
        position: absolute;

        display: block;

        width: 6px;
        height: 5px;
        margin: auto;

        content: '';

        border-right: 3px solid transparent;
        border-bottom: 5px solid;
        border-left: 3px solid transparent;

        inset: 0;
    }


    .new_delivery .row .btn + .btn
    {
        top: auto;
        bottom: 0;

        border-top: 1px solid #dbe0e4;
    }

    .new_delivery .row .btn + .btn:before
    {
        transform: rotate(180deg);
    }

</style>