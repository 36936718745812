<template>
    <nav class="menu row">
        <div><router-link to="/" class="link" :class="{ active: route.path == '/' }">
            {{ $t('message.menu_link1') }}
        </router-link></div>

        <div><router-link to="/offer_planning" class="link" :class="{ active: route.path == '/offer_planning' }">
            {{ $t('message.menu_link2') }}
        </router-link></div>

        <div><router-link to="/abc_analysis" class="link" :class="{ active: route.path == '/abc_analysis' }">
            {{ $t('message.menu_link3') }}
        </router-link></div>
    </nav>
</template>


<script setup>
    import { useRoute } from 'vue-router'

    const route = useRoute()
</script>


<style scoped>
    .menu
    {
        margin-right: 40px;
    }


    .menu > * + *
    {
        margin-left: 60px;
    }


    .menu .link
    {
        color: #c0c0c0;
        font-size: 16px;

        position: relative;

        display: block;

        padding: 25px 0;

        transition: .2s linear;
        text-decoration: none;
    }

    .menu .link:after
    {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;

        width: 0;
        height: 3px;
        margin: auto;

        content: '';
        transition: width .3s linear;

        background: #49aa4f;
    }


    .menu .link:hover
    {
        color: #fff;
    }


    .menu .link.active
    {
        color: #fff;
        font-weight: 600;
    }

    .menu .link.active:after
    {
        width: 100%;
    }



    @media print, (max-width: 1279px)
    {
        .menu > * + *
        {
            margin-left: 48px;
        }


        .menu .link
        {
            font-size: 15px;

            padding: 20px 0;
        }
    }



    @media print, (max-width: 1023px)
    {
        .menu > * + *
        {
            margin-left: 32px;
        }


        .menu .link
        {
            font-size: 14px;
        }
    }



    @media print, (max-width: 767px)
    {
        .menu
        {
            display: block;
            overflow: auto;

            width: calc(100% + 48px);
            margin: 0 -24px;
            padding: 0 24px;

            white-space: nowrap;

            order: 3;
            scrollbar-color: transparent transparent !important;
            scrollbar-width: thin !important;
        }

        .menu::-webkit-scrollbar
        {
            display: none !important;

            width: 0 !important;
            height: 0 !important;

            background-color: transparent !important;
        }

        .menu::-webkit-scrollbar-thumb
        {
            background-color: transparent !important;
        }


        .menu > *
        {
            display: inline-block;

            max-width: none;
            margin: 0;

            vertical-align: top;
        }

        .menu > * + *
        {
            margin-left: 36px;
        }
    }
</style>