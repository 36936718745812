<template>
    <section class="modal">
        <div class="modal_content" @click.self="emitter.emit('closeCreatingDeliveryModal')">
            <div class="data">
                <button class="close_btn" @click.prevent="emitter.emit('closeCreatingDeliveryModal')">
                    <svg class="icon"><use xlink:href="@/assets/sprite.svg#ic_close"></use></svg>
                </button>


                <div class="modal_title">
                    {{ $t('message.adding_all_to_delivery_modal_title', { count: 342 }) }}
                </div>


                <div class="modal_data">
                    <!-- Add new delivery -->
                    <AddNewDelivery />

                    <div class="btns">
                        <button class="cancel_btn btn" @click.prevent="emitter.emit('closeRemovingFromDeliveryModal')">
                            {{ $t('message.btn_cancel') }}
                        </button>

                        <button class="add_btn btn">
                            {{ $t('message.btn_add_product_to_delivery') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="overlay" @click.prevent="emitter.emit('closeCreatingDeliveryModal')"></div>
    </section>
</template>


<script setup>
    import { inject } from 'vue'

    // Components
    import AddNewDelivery from '@/components/AddNewDelivery.vue'


    const emitter = inject('emitter')
</script>


<style scoped>
    .new_delivery
    {
        margin-top: 0;
    }



    .btns
    {
        display: flex;

        margin-top: 30px;

        justify-content: space-between;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
    }


    .btns .btn
    {
        color: #49aa4f;
        font-weight: 600;

        width: calc(50% - 10px);
        height: 45px;

        transition: .2s linear;

        border: 1px solid #dbe0e4;
        border-radius: 4px;
    }

    .btns .btn.add_btn
    {
        color: #fff;

        border-color: #49aa4f;
        background: #49aa4f;
    }

    .btns .btn.add_btn
    {
        pointer-events: none;

        border-color: #92cc95;
        background: #92cc95;
    }


    .btns .btn:hover
    {
        background: #f6f7f8;
    }

    .btns .btn.add_btn:hover
    {
        border-color: #3b9340;
        background: #3b9340;
    }



    @media print, (max-width: 767px)
    {
        .btns .btn
        {
            width: calc(50% - 4px);
        }
    }



    @media print, (max-width: 479px)
    {
        .btns .btn
        {
            width: 100%;
        }

        .btns .btn + .btn
        {
            margin-top: 8px;
        }
    }
</style>