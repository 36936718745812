<template>
    <div class="logo">
        <img src="@/assets/logo.svg" alt="">
    </div>
</template>


<style scoped>
    .logo
    {
        margin-right: auto;
    }

    .logo img
    {
        display: block;
    }
</style>